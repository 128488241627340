/**
 * Created by ozan on 02.02.16.
 */

function validateEmail( email ) {
  var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test( email );
}

function form2mail() {


  var submit = false;
  var mail = getCookie( "mailsenthnooffenbachde" ) == "" && navigator.cookieEnabled ? true : false;
  if ( !validateEmail( document.getElementById( 'email' ).value ) ) {
    document.getElementById( 'email' ).style.borderColor = "red";
  } else {
    document.getElementById( 'email' ).style.borderColor = "green";
  }
  if ( document.getElementById( 'name' ).value == "" ) {
    document.getElementById( 'name' ).style.borderColor = "red";
  } else {
    document.getElementById( 'name' ).style.borderColor = "green";
  }
  if ( document.getElementById( 'regard' ).value == "" ) {
    document.getElementById( 'regard' ).style.borderColor = "red";
  } else {
    document.getElementById( 'regard' ).style.borderColor = "green";
  }
  if ( document.getElementById( 'textarea' ).value == "" ) {
    document.getElementById( 'textarea' ).style.borderColor = "red";
  } else {
    document.getElementById( 'textarea' ).style.borderColor = "green";
  }
  if ( document.getElementById( 'privacy' ).checked == false ) {
    document.getElementById( 'check1' ).style.color = "red";
  } else {
    document.getElementById( 'check1' ).style.color = "black";
  }

  if ( validateEmail( document.getElementById( 'email' ).value )
    && document.getElementById( 'name' ).value != ""
    && document.getElementById( 'regard' ).value != ""
    && document.getElementById( 'textarea' ).value != ""
    && document.getElementById( 'privacy' ).checked != false ) {
    if ( !navigator.cookieEnabled ) {
      $( '#nocookie' ).modal( 'show' );
    }
    if ( navigator.cookieEnabled && !mail ) {
      $( '#again' ).modal( 'show' );
    }
  }


  if ( validateEmail( document.getElementById( 'email' ).value )
    && document.getElementById( 'name' ).value != ""
    && document.getElementById( 'regard' ).value != ""
    && document.getElementById( 'textarea' ).value != ""
    && document.getElementById( 'privacy' ).checked != false
    && mail
    && navigator.cookieEnabled ) {
    $( '#mloading' ).modal( 'show' );
    submit = true;


    $.post( "/actions/form2mail", {
      name: document.getElementById( 'name' ).value,
      email: document.getElementById( 'email' ).value,
      regard: document.getElementById( 'regard' ).value,
      textarea: document.getElementById( 'textarea' ).value,
      submit: submit
    } )
      .done( function ( data ) {
        $( '#mloading' ).modal( 'hide' );
        if ( data = "true" ) {
          $( '#success' ).modal( 'show' );
          setCookie( "mailsenthnooffenbachde", "true", 6 );
        } else {
          $( '#error' ).modal( 'show' );
        }

      } );

  }
}