/**
 * Created by ozan on 08.12.15.
 */

var animation = true;
var started = false;
var ids = [];
var subpos = 1;
var linkpos = 0;

var path = window.location.pathname.substr( 1, window.location.pathname.length ).split( '/' );
var link = null;
var sublink = null;

var scrollLock = false;

function setCookie( cname, cvalue, exhours ) {
  var d = new Date();
  d.setTime( d.getTime() + (exhours * 60 * 60 * 1000) );
  var expires = "expires=" + d.toUTCString();
  document.cookie = cname + "=" + cvalue + "; " + expires;
}

function getCookie( cname ) {
  var name = cname + "=";
  var ca = document.cookie.split( ';' );
  for ( var i = 0; i < ca.length; i++ ) {
    var c = ca[i];
    while ( c.charAt( 0 ) == ' ' ) c = c.substring( 1 );
    if ( c.indexOf( name ) == 0 ) return c.substring( name.length, c.length );
  }
  return "";
}

function checkLanguage() {
  var languages = ['de', 'en'];
  for ( var i = 0; i < languages.length; i++ ) {
    if ( languages[i] == path[0] ) {
      subpos = 2;
      linkpos = 1;
    }
  }
}

function pushState( url ) {
  window.history.pushState( { slimRoute: true }, null, url );
}

function replaceState( url ) {
  window.history.replaceState( { slimRoute: true }, null, url );
}

//animations

function fadeout() {
  $( '.navbar-collapse' ).collapse( 'hide' );
  $( '#loading' ).html( $( "#content" ).html() ).show();
  eraseIds();
  getNewContent();
}

function fadein() {
  $( "#loading" ).css( { "opacity": "1" } ).animate( {
      "opacity": "0"
    },
    500, function () {
      $( "#loading" ).hide();
      $('body').scrollTop(0);
      $( "#content" ).show().css( { "opacity": "0" } ).animate( {
          "opacity": "1"
        },
        500, function () {
          showSublinks( link );
          $( this ).trigger( 'scroll' );
        } );
    } );
  addIds();
}

//ajax
function getNewContent() {
  started = true;
  $.get( window.location, function ( data ) {
    $( "title" ).html( $( data ).filter( 'title' ).html() );
    $( '.dynamic' ).each( function ( i, obj ) {
      $( obj ).hide().empty();
      $( data ).filter( '.dynamic' ).each( function ( j, newObj ) {
        if ( $( obj ).id == $( newObj ).id ) {
          $( obj ).html( $( newObj ).html() );
        }
      } );
      fadein();
    } );
  } );
}

function detectSublinkContent() {
  $( window ).on( 'resize load scroll', function () {
    var topbutton = $( ".top-button" );

    if ( Math.abs( $( document ).scrollTop() ) > 400 ) {
      topbutton.fadeIn();
    }

    if ( Math.abs( $( document ).scrollTop() ) < 399 ) {
      topbutton.fadeOut();
    }

    for ( var i = 0; i < ids.length; i++ ) {
      removeActive( ids[i] );
      if ( $( '#' + ids[i] + '_content' ).offset().top - $( document ).scrollTop() < $( window ).height() / 5 && $( '#' + ids[i] + '_content' ).offset().top - $( document ).scrollTop() > $( window ).height() / 5 - $( '#' + ids[i] + '_content' ).height() ) {

        if ( !scrollLock ) {
          if ( sublink == null ) {
            replaceState( link + '/' + ids[i] );
            sublink = ids[i];
          } else {
            replaceState( ids[i] );
          }
        }
          setActive( ids[i] );

      }
    }
  } );
}

function sublinkClick( id ) {


  scrollToContent( id );
}

function fixUrl() {
  if ( path[1] == '' ) {
    replaceState(  "../" + link );
    window.location.reload();
  }
  if ( path[2] == '' ) {
    replaceState(  "../" + sublink );
    window.location.reload();
  }
}

function scrollToTop() {
  //scrollLock = true;
  //window.history.replaceState( null, null, sublink != null ? "../" + link : link );
  //sublink = null;
  $( "html, body" ).animate( { scrollTop: 0 - 200 }, "slow", function() {
   // scrollLock = false;
  } );
}

function scrollToContent( id ) {
  scrollLock = true;
  sublink = id;
  $( 'html,body' ).animate( {
      scrollTop: $( '#' + id + '_content' ).position().top
    }, "slow", function() {
    scrollLock = false;
  } );
  $( '#' + id + '_content' ).focus();
}

function addIds() {
  $( '.' + link + ' .sublink' ).each(function() {
    addId( this.id );
  });
}

function addId( id ) {
  ids.push( id );
}

function eraseIds() {
  while ( ids.length > 0 ) {
    ids.pop();
  }
}

function setActive( link ) {
  $( '#' + link ).addClass( 'active' );
}

function removeActive( link ) {
  $( '#' + link ).removeClass( 'active' );
}

function showSublinks( link ) {
  $( '.' + link ).hide();
  $( '.' + link ).removeClass( 'hidden' );
  $( '.' + link ).fadeIn();
}

function hideSublinks( link ) {
  $( '.' + link ).fadeOut( function () {
    $( '.' + link ).addClass( 'hidden' );
  } );
}

$( document ).ready( function () {
  // setting tag for first visit
  replaceState(null);

  // BAUSTELLE
  $('body').scrollTop(0);
  checkLanguage();
  fixUrl();


  // SETTING LINK & SUBLINKS from PATH
  for ( var i = 0; i < path.length; i++ ) {
    if ( i == linkpos ) {
      link = path[i] == '' ? 'index' : decodeURIComponent( path[i] );
      setActive( link );
    }
    else if ( i == subpos ) {
      sublink = decodeURIComponent( path[i] );
      setActive( sublink );
    }
  }

  //fade in Sublinks
  showSublinks( link );

  //Scroll to sublink
  if ( sublink != null ) {
    scrollToContent( sublink );
  }

  addIds();

  //ON LINK CLICK
  $( document ).on( "click", ".link", function ( e ) {
    pushState( sublink != null ? '../' + this.id : this.id );
    removeActive( link );
    removeActive( sublink );
    hideSublinks( link );

    setActive( this.id );
    link = this.id;
    sublink = null;
    if ( animation == false ) {
      window.location.reload();
    } else {
      fadeout();
    }
  } );

  //ON SUBLINK CLICK
  $( document ).on( "click", ".sublink", function ( e ) {
    sublinkClick( this.id );
  } );

  //ON BACK AND FORWARD
  $(window).on('popstate', function(e) {
    if (!e.originalEvent.state.slimRoute) return; // not my problem
    removeActive( link );
    removeActive( sublink );
    hideSublinks( link );
    path = window.location.pathname.substr( 1, window.location.pathname.length ).split( '/' );
    link = null;
    sublink = null;
    for ( var i = 0; i < path.length; i++ ) {
      if ( i == linkpos )
        link = path[i] == '' ? 'index' : decodeURIComponent( path[i] );
      else if ( i == subpos )
        sublink = decodeURIComponent( path[i] );
    }
    setActive( link );
    if ( animation == false ) {
      window.location.reload();
    } else {
      fadeout();
    }
    if ( sublink != null ) {
      setTimeout( function () {
        setActive( sublink );
        scrollToContent( sublink );
      }, 1000 );
      ;
    }
  });
  detectSublinkContent();
} );