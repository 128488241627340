/**
 * Created by ozan on 28.05.15.
 */

//Scroll effects
//firstpagecurtainscroll



//Curtain & Intros
function curtainOut() {
  $('#curtain' ).addClass('hidden');
  $('#content' ).removeClass('hidden');
}


function maximizePic( id ) {
  if ( $( window ).width() >= 768 ) {
    $( '#' + id ).animate( {
      'width': '80%',
      'height': '80%',
      'left': '10%',
      'background-position': 'center'
    }, 1000 );
  }
}

function minimizePic( id ) {
  if ( $( window ).width() >= 768 ) {
    $( '#' + id ).animate( {
      'width': '40%',
      'height': '40%',
      'left': '2%',
    }, 1000 );
  }
}

$( document ).ready( function() {
  //detectOnScroll();
 /* if ( $( window ).width() >= 1009 ) {
    config = {
      ui: {
        flyout: "middle right",
        buttonText: "teilen"
      },
      networks: {
        reddit: {
          enabled: false
        },
        email: {
          enabled: false
        },
        whatsapp: {
          enabled: false
        }
      }
    };
  } else
  {
    config = {
      ui: {
        flyout: "top left",
        buttonText: "teilen"
      },
      networks: {
        reddit: {
          enabled: false
        },
        email: {
          enabled: false
        },
        whatsapp: {
          enabled: false
        }
      }
    };
  }


  var share = new ShareButton( config);
*/

});

